import React, { CSSProperties } from 'react';
import ReactPlayer              from 'react-player/lazy';

export type Transaction =   { type: TransactionType, payload?: any };
export enum TransactionType {
  PLAY   = 'PLAY',
  PAUSE  = 'PAUSE',
  RESUME = 'RESUME',
};

export interface VideoProps {
  url          : string;
  mobileUrl?   : string;
  transactions?: Transaction[];
  width?       : string;
  height?      : string;
  show?        : boolean;   
  orientation? : 'portrait' | 'landscape';
  style?       : CSSProperties;
  onProgress?  : (event: {playedSeconds: number}) => void;
  onSeek?      : (seekedSeconds: number) => void;
  onPlay?      : () => void;
  onEnded?     : () => void;
};

const VideoPlayer = ({
  url,
  transactions = [],
  orientation  = 'landscape',
  width        = '100%',
  height       = '100%',
  style        = {},
  onProgress   = () => {},
  onSeek       = () => {},
  onPlay       = () => {},
  onEnded      = () => {},
}: VideoProps) => {
  const [playing, setPlaying] = React.useState(true);

  /*******************************************************************
   * Handle transactions
   ******************************************************************/
  const handleTransactions = (transactions: Transaction[]) => {
    transactions.forEach( ({type}) => {
      switch (type) {
        case TransactionType.PLAY: {
          setPlaying(true);
          break;
        }
        case TransactionType.RESUME: {
          setPlaying(true);
          break;  
        }
        case TransactionType.PAUSE: {
          setPlaying(false);
          break;
        }
        default: break;
      };
    });
  }

  /***********************************************************************/
  /*****                      Use Effects                            *****/
  /***********************************************************************/
  React.useEffect(() => { handleTransactions(transactions) }, [transactions]);

  /***********************************************************************/
  /*****                      Components                             *****/
  /***********************************************************************/
  return (
    <div 
      style     = {style}
      className = {`
        mx-auto h-full w-full
        overflow-hidden
        bg-black
      `}
    >
      <ReactPlayer 
        controls
        playing     = {playing}
        url         = {url}
        width       = {width}
        height      = {height}
        onProgress  = {onProgress}
        onSeek      = {onSeek}
        onPlay      = {onPlay}
        onEnded     = {onEnded}
        playsinline = {true}
        config      = {{
          vimeo: {
            playerOptions: {
              fullscreen : false // This disables the fullscreen button
            }
          }
        }}
      />
    </div>
  );
};

export default VideoPlayer;
